@media (max-width: 1080px) {
  .diagram-component {
    width: 800px;
    height: 430px;
    border: 1px solid black;
  }
}

@media (max-width: 1200px) and (min-width: 1081px) {
  .diagram-component {
    width: 900px;
    height: 430px;
    border: 1px solid black;
  }
}

@media (max-width: 1440px) and (min-width: 1201px) {
  .diagram-component {
    width: 1140px;
    height: 500px;
    /* border: 1px solid black; */
  }
  .drg-diagram-component {
    /* width: 1250px; */
    height: 150px;
    /* border: 1px solid black; */
  }
}

@media (min-width: 1441px) {
  .diagram-component {
    width: 1300px;
    height: 500px;
    /* border: 1px solid black; */
  }
  .drg-diagram-component {
    /* width: 1300px; */
    height: 150px;
    /* border: 1px solid black; */
  }
}

.diagram-container {
  padding-top: 20px;
  padding-left: 20px;
}

label {
  font: 300 1rem Montserrat, sans-serif;
  color: #212121;
  display: block;
  padding: 2px;
}

p {
  font: 300 1rem Montserrat, sans-serif;
  color: #212121;
}
