.footerContainer {
  width: 100%;
  padding-top: 20px;
  position: absolute;
  bottom: 0;
}

.copyrightContainer {
  margin-top: 6px;
  margin-right: 20px;
  text-align: right;
}
.copyrightText {
  height: 15px;
  width: 40px;
  color: #999999;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
  font-family: "ProximaNova-Regular";
}

.css-1qchd47-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 0px !important;
}
.css-1qchd47-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 0px !important;
}
