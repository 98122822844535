.editor {
  margin: 6% 2% 2%;
}

.editor .editor-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}

.editor .section-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.editor .editor-button {
  border-radius: 4px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
  margin: 5px !important;
}

.editor .editor-content-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px 4px 0px 0px;
  margin-top: 2%;
  /* margin-bottom: 2%; */
  padding: 0.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.editor .editor-content-header-first-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.editor .editor-content-header-first-section .first-subsection-align {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.editor .editor-content-header-last-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
/* .interactive-link {
    padding-left: 20px !important;
  } */

.editor .MuiOutlinedInput-input {
  padding: 10px 10px !important;
}

.editor .handle-ruleNo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editor .handle-lock-icon {
  min-width: 30px;
}

.align-view-layout {
  max-width: 390px !important;
  max-height: 400px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.align-view-Typography {
  min-width: 118px !important;
  margin: 1% !important;
}

.form-section-editor {
  max-width: 400px !important;
  max-height: 400px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.editor-groupCodes {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: 350px;
}
.editor-groupCodes-sectionLeft {
  /* width: 50%; */
  /* flex-grow: 1; */
  max-width: 50%;
  border-right: 2px solid #fafafa;
  width: 100%;
  padding: 0% 2%;
  height: 350px;
}

.editor-groupCodes-sectionRight {
  /* width: 50%; */
  /* flex-grow: 1; */
  max-width: 50%;
  width: 100%;
  padding: 0% 2%;
  height: 350px;
}

.align-groupCodes {
  max-height: 65%;
  min-height: 65%;
  height: 65%;
  overflow-y: scroll;
  margin: 5% 0%;
}

.align-groupCodes-left {
  /* max-height: 65%;
  min-height: 65%;
  height: 65%;
  overflow-y: scroll; */
  margin: 5% 0%;
}

.typographySubHeading {
  color: #000000;
  font-size: 14px !important;
}
.customDiv {
  padding: 0 2%;
}
.groupcode-grid {
  padding: 0px 10px;
}
.groupcode-header-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.groupcode-title {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  padding: 20px;
}
.groupcode-button {
  height: 40px !important;
  width: 140px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
.groupcode-content-header {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.countingCode-button-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding-top: 10px;
}
.countingCode-button-container .editor-button {
  border-radius: 4px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
  margin: 5px !important;
}

.view-cellName {
  font-weight: 500 !important;
}
.view-cellValue {
  min-height: 25px;
  border: 1px solid;
  text-align: center;
}

.MuiDataGrid-panelFooter {
  display: none !important;
}

.MuiDataGrid-panel {
  top: -140px !important;
  left: 300px !important;
}

.changeLog-container {
  padding: 20px;
}
.changeLog-section-container {
  margin-top: 6%;
  width: 100%;
}
.changeLog-title {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}

.changeLog-sub-text {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.changeLog-container .table-container {
  border: 1px solid #e0e0e0;
  border-radius: 4px 4px 4px 4px;
  margin: 15px 20px 10px;
}
.changeLog-container .section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px;
}
.changeLog-container .table-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px;
}
.changeLog-container .table-section-header {
  flex-grow: 1;
}
.table-section-header-left {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
.changeLog-container .right-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.changeLog-container .MuiOutlinedInput-input {
  padding: 10px 10px !important;
}
.changeLog-container .search {
  border: 1px solid #0000001f;
  color: #00000040;
  opacity: 1;
}
.changeLog-container .table {
  max-height: 400px;
}
.changeLog-container .tableCell.head {
  border-top: 1px solid #e0e0e0;
  padding: 8px 5px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center;
}
.changeLog-container .tableCell {
  border-right: 1px solid #e0e0e0;
  padding: 2px 5px !important;
  text-align: center;
}

.header-layout {
  display: flex;
  justify-content: space-between;
}

.editor-backButton{
  border-radius: 4px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
  margin: 12px !important;
}

.editor .MuiDataGrid-pinnedColumnHeaders{
  border-bottom: 2px solid rgba(224, 224, 224, 1) !important;
}