.registerContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
  .registerContainer.admin{
    padding-top: 75px;
  }
  .card {
    width: 448px;
    max-width: 426px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-around;
    align-items: center;
  }
  .form-title {
    height: 32px;
    width: 400px;
    color: #000000;
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
    text-align: left;
    padding: 10px 0;
  }
  .form-section-header {
      width: 400px;
  }
  .form-section {
      height: 85px;
      width: 400px;
  }
  .form-section-double {
      height: 85px;
      width: 400px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: space-around;
      justify-content: space-between;
  }
  .form-sub-section {
      height: 97px;
      width: 192px;
    }
  .form-button-section {
      height: 40px;
      width: 400px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: flex-end;
    }
  .form-button-container {
      padding: 10px;
    }
  .form-button-primary {
      height: 40px;
      width: 190px;
      margin-right: 10px;
      padding: 10px;
      text-transform: uppercase !important;
      letter-spacing: 1.25px;
    }
  .form-button-secondary {
      height: 40px;
      padding: 10px;
      border-color: white;
      text-transform: uppercase !important;
      letter-spacing: 1.25px;
  }
  .footerContainer
    {
      position: inherit;
    }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }