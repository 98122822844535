.content-upload-container {
  padding: 20px;
}
.content-section-container {
  margin-top: 6%;
  width: 100%;
}
.content-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.content-upload-title {
  margin-bottom: 0px !important;
}
.form-button-primary {
  height: 40px !important;
  width: 177px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.editorContainer {
  width: 100%;
}
.editor-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid rgb(150, 150, 150);
}
.editor-section-header {
  flex-grow: 1;
  align-self: center;
}
.editor-right-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.dropdown-section {
  width: 200px;
}
.editing-text {
  font-size: 14px !important;
  font-weight: bold !important;
}
.editing-description {
  font-size: 14px !important;
}
