.dashboardContainer {
  padding-top: 57px !important;
  /* height: 560px; */
  min-height: 86vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 20px;
}
.name-container {
  padding-top: 20px !important;
  height: 250px !important;
  width: 100%;
  border-radius: 0px !important;
  background: "transparent linear-gradient(272deg, #0076AE 0%, #250056 100%) 0% 0% no-repeat padding-box";
  background-image: linear-gradient(
    272deg,
    rgb(0, 118, 174) 0%,
    rgb(37, 0, 86) 100%
  );
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: initial;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: padding-box;
  background-clip: padding-box;
  background-color: transparent;
}
.card-design {
  text-align: center !important;
  padding: 16px !important;
  min-height: 365px !important;
}
.avatar-design {
  padding: 16 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.title {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.hero-design {
  padding-top: 60px !important;
  height: 350px !important;
}
.grid-container {
  position: absolute !important;
  top: 200px !important;
}
.manager-avatar {
  background-color: #ebf2fc !important;
  color: blue;
}
.button-margin {
  margin-right: 10px;
}
.first-grid {
  padding-left: 20px !important;
  padding-right: 10px !important;
}
.middle-grids {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.last-grid {
  padding-left: 10px !important;
  padding-right: 20px !important;
}

.dashboardContainer .MuiGrid-root {
  justify-content: center !important;
}
