.interactive-container {
  padding: 20px;
}
.interactive-section-container {
  margin-top: 6%;
  width: 100%;
}
.interactive-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.interactive-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.interactive-header-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 770px;
  align-items: center;
}
.interactive-title {
  margin-bottom: 0px !important;
}
.interactive-button-news {
  height: 35px !important;
  /* width: 160px !important; */
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.interactive-button-guidance {
  height: 35px !important;
  /* width: 190px !important; */
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.interactive-button-guide {
  height: 35px !important;
  /* width: 220px !important; */
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.interactive-button-clear {
  height: 35px !important;
  /* width: 150px !important; */
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.form-button-primary {
  height: 35px !important;
  width: 175px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.interactive-section {
  border: 1px solid white;
}
.interactive-content-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px 20px;
  align-items: center;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}
.interactive-content {
  width: 96%;
  margin: 25px;
  border: 1px solid lightgrey;
}
.interactive-text {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.interactive-link {
  padding-left: 20px !important;
}

.interactive-accordion-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.patient-information-1 {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 15px 10px;
}
.patient-information-2 {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 10px;
  background-color: #f8f9fb;
}
.patient-information-female {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 15px 0px;
  width: 43%;
  background-color: #f8f9fb;
}
.patient-information-3 {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 15px 10px;
}
.patient-information-4 {
  width: 40%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.patient-information-4 .MuiTextField-root {
  width: 50% !important;
  padding-left: 5px;
  padding-right: 5px;
}
.interactive-grid {
  padding: 15px;
}
.diagnosis-tree-container {
  width: 100%;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}
.interactive-paper-selected {
  background-color: #f8f9fb !important;
  /* padding-top: 20px; */
}
.selection-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;
}
.selection-button-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}
.interactive-query-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
}
.run-query-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  /* width: 350px; */
}
.selected-diagnosis {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}
.selected-diagnosis-item {
  padding: 10px 0px;
}
.selected-primary {
  border: 1px solid #0000001f;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 93%;
  padding: 2px 10px;
  margin-inline-start: 13px;
  border-radius: 0px !important;
}
.selected-diagnosis-button {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.selected-subcode {
  border: 1px solid #0000001f;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 90%;
  padding: 2px 10px;
  margin-inline-start: 30px;
  align-items: center;
  border-radius: 0px !important;
}
.selected-text {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}
.interactive-space {
  padding: 5px;
}
.interactive-empty {
  padding-right: 20px;
}
.spell-container {
  padding: 0px 10px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}
.spell-navigation {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.spell-item-container {
  padding-right: 20px;
  width: 30%;
}
.spell-paper {
  border: 1px solid lightgrey;
  width: 360px;
}
.spell-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 30px;
}
.spell-header-bold {
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.spell-content-bold {
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}
.spell-content {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  white-space: normal;
}
.spell-hidden-span {
  padding: 10px;
}
.spell-request-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.spell-contact-days {
  padding: 10px;
}
.spell-run-request {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 20px;
}
.spells ul {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding-left: 5px;
}
.spells li {
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin: 0 4px;
  white-space: nowrap;
}
.spell-primary-diagnosis {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  align-content: center;
  flex-direction: row;
  height: 55px;
}
.spell-patientInfo {
  height: 35px;
}
.episodes-container ul {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  flex-wrap: wrap;
  padding-left: 5px;
}
.episodes-container li {
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin: 0 4px;
  white-space: nowrap;
}
.episode-paper-collapse {
  border: 1px solid lightgrey;
  width: 360px;
}
.episode-paper-expand {
  border: 1px solid lightgrey;
  width: 100%;
}
.episode-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 30px;
}
.episode-toolbar {
  display: flex;
  align-items: center;
}
.episode-expand-link {
  padding-left: 20px !important;
  font-size: 12px !important;
}
.episode-menuItem {
  font-size: 12px !important;
}
.episode-listItemIcon {
  min-width: 30px !important;
}
.episode-content-bold {
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  padding: 5px !important;
  line-height: 1 !important;
}
.episode-content {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  white-space: normal;
  padding: 5px !important;
  line-height: 1 !important;
}
.episode-expand {
  width: 100%;
  display: block;
}
.episode-collapse {
  width: 360px;
  display: flex;
}
.episode-selected-diagnosis-item {
  padding: 10px 0px;
}
.episode-selected-primary {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 90%;
  padding: 2px 10px;
  margin-inline-start: 13px;
  border-radius: 0px !important;
}

.episode-selected-subcode {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 85%;
  padding: 2px 10px;
  margin-inline-start: 32px;
  align-items: center;
  border-radius: 0px !important;
}
.episode-diagnosis-bold {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  font-weight: bold !important;
}
.episode-diagnosis-content {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  white-space: normal;
}
.grid-header-text {
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  padding-bottom: 2px !important;
}
.grid-patientInfo {
  margin-left: 10px;
}
.result-container {
  padding: 10px;
  /* background-color: #f4f4f480 !important; */
}
.results ul {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding-left: 5px;
}
.results li {
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  margin: 0 1px;
  white-space: nowrap;
}
.report-list ul {
  /* display: flex;
  align-items: center; */
  list-style: none;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding-left: 5px;
  /* flex-direction: row;
  flex-wrap: wrap; */
}
.report-list li {
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  margin: 0 1px;
  white-space: nowrap;
}
.result-paper {
  border: 1px solid lightgrey;
  width: 365px;
  height: 380px;
}
.result-report-paper {
  border: 1px solid lightgrey;
  /* width: 365px; */
  /* height: 530px; */
}
.result-accordion {
  background-color: #f4f4f480 !important;
}
.result-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  /* width: 13%; */
}
.result-patient-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}
.result-episode-placeholder-1 {
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
}
.result-episode-content {
  padding-right: 20px;
}
.result-selected-diagnosis-item {
  padding: 5px 0px;
}
.patient-information-link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
}
.interactive-report-dialog .MuiDialog-paper {
  max-width: 72% !important;
}
.interactive-report-container {
  width: fit-content;
}

.introjs-tooltip {
  min-width: 100% !important;
  max-width: 100% !important;
}
.introjs-tooltipReferenceLayer,
.introjs-button,
.introjs-tooltiptext,
.introjs-skipbutton {
  font-family: "Montserrat" !important;
  font-size: 12px !important;
}
.info-stepper {
  min-width: 400px !important;
  max-width: 500px !important;
}
.introjs-tooltip-header {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px !important;
}
.introjs-tooltip-title {
  font-family: "Montserrat" !important;
  font-size: 13px !important;
}
.introjs-tooltiptext {
  padding: 10px 15px !important;
}
.introjs-button {
  padding: 5px !important;
  background-color: #015ff1 !important;
  color: white !important;
  text-decoration: none !important;
  text-shadow: none !important;
}
.report-interactive-text {
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.report-episode-content-bold {
  font-size: 10px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  padding: 5px !important;
  line-height: 1 !important;
}
.report-episode-content {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  white-space: normal;
  padding: 5px !important;
  line-height: 1 !important;
}
.report-wrapper {
  /* width: 90%; */
}
.report-header-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.report-episode-diagnosis-bold {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  font-weight: bold !important;
}
.report-episode-diagnosis-content {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  white-space: normal;
}
.interactive-visual-dialog .MuiDialog-paper {
  max-width: 90% !important;
}
@media print {
  .result-container.page-break {
    margin: 1rem;
    display: block;
    page-break-after: always;
  }
}

.printOverviewLayout{
  margin: 1rem;
}

.MuiMenuItem-root{
  min-height: 30px !important;
}
.MuiAccordionSummary-content{
  align-items: center;
}