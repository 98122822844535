/* .drg-test .header-section-container {
  width: 96%;
  margin: 25px;
  border: 1px solid #00a1df;
} */
/* .drg-test .logo-style {
  margin-top: 4%;
  padding-left: 50px;
} */
.header-section-container {
  margin-top: 1% ;
}
.drg-test{
  padding: 20px;
}

.drg-test .header-section {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #00a1df !important;
}
.drg-test .header-text {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  color: white !important;
  padding: 0px 15px;
}
.drg-test .download-button {
  height: 35px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background-color: #005487 !important;
}

/* .drg-test .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
} */

.drg-test .copyright-placeholder {
  padding: 0px 45px;
}
.drg-test .copyrightText {
  color: #999999;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.drg-test .text-header {
  padding: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  color: #232323;
}
.drg-test .text {
  padding: 0px 20px !important;
  color: #232323 !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}
.drg-test .text-last {
  padding: 0px 20px !important;
  color: #232323 !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}
.drg-container-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.drgFrontPageContent {
  max-height: 500px;
  overflow-y: scroll;
  padding: 1%;
}