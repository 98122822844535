.cardContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}
.publisher-card {
  width: 448px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
}
.publisher-title {
  height: 32px;
  width: 380px;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  padding-top: 15px;
}
.publisher-section-header {
  height: 75px;
  width: 380px;
}
.publisher-section {
  height: 80px;
  width: 380px;
}
.publisher-section-double {
  height: 90px;
  width: 380px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
}
.publisher-sub-section {
  /* height: 97px; */
  width: 192px;
}
.publisher-tharif-section {
  width: 180px;
}
.publisher-url-section {
  padding-bottom: 10px;
  width: 380px;
}
.url-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.publisher-action-section {
  width: 380px;
}
.publisher-button-section {
  /*   height: 140px; */
  width: 380px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 5px;
}
.publisher-button-container {
  padding: 10px;
}
.publisher-button-primary {
  height: 40px;
  width: 126px;
  margin-right: 10px;
  padding: 10px;
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
}
.publisher-button-secondary {
  height: 40px;
  width: 81px;
  margin-right: 10px;
  padding: 10px;
  border-color: white;
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
}
.place-error-message {
  height: 24px;
  width: 380px;
  color: #e20000;
  font-family: "Proxima Nova";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
}
.publisher-text-section {
  width: 380px;
}
.publisher-text {
  padding-top: 10px;
  padding-bottom: 10px;
  /* font-size: 14px !important; */
}
.link-template {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
