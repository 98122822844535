.manageuser .paperContainer{
margin-top: 6%;
width: 100%;
}
.manageuser .table-container{
    border: 1px solid #E0E0E0;
    border-radius: 4px 4px 4px 4px;
    margin: 15px 30px 10px;
}
.manageuser .section-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px;
}
.manageuser .table-section-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px;
}
.manageuser .table-section-header{
    flex-grow: 1;
}
.manageuser .right-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.manageuser .search{
    border: 1px solid #0000001F;
    color: #00000040;
    opacity: 1;
}
.manageuser .table{
    max-height: 400px;
}
.manageuser .tableCell.head{
    border-top: 1px solid #E0E0E0;
    padding: 8px 5px !important;
}
.manageuser .tableCell{
    border-right: 1px solid #E0E0E0;
    padding: 2px 5px !important;
}
.manageuser .section-footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 10px 30px;
}
.manageuser .section-footer .form-button-primary {
    height: 40px !important;
    width: 177px !important;
    text-transform: uppercase !important;
    letter-spacing: 1.25px !important;
}