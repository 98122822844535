body {
  margin: 0px;
}

.logo-footer {
  height: 13px;
  width: 70px;
  margin-top: 10px;
}

.MuiButton-label{

font-size: 12px;
font-weight: 500;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.copy-container{
  margin-top: 2px;
  margin-right: 20px;
  text-align: right;
}

.copy-right{

  height: 15px;
  width: 40px;
  color: #595959;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;

}

.logo-iqvia {
  margin-left: auto;
  margin-right: auto;
  display: block;   
  margin-top: 24px;
}

.loginImage{

  background-image: url(../../assets/images/photo-login.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  width: 100%;
  position: relative;  
}

.login-button{    
  margin-top: 24px;
  margin-left: 31px;
  margin-right: 31px;
}

.casemix360-text {
   position: absolute;
   bottom: 50px;
   left: 50px;
   right: 50px;
  }

  .req-access{
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
 

  .cstLink {
    color: #0768FD;
    cursor: pointer;
    text-decoration: underline;
}

  .text-link {   
    color: #0768FD !important; 
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 10px;
  }

  .login-card {     
    margin-left: 60px;
    margin-right: 60px;   
  }

  .logo-container{
    text-align: 'left';
     padding-left: 20px;
  }
