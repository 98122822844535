.manager-button{
    border-radius: 4px !important;
    text-transform: uppercase !important;
    letter-spacing: 1.25px;
    margin: 5px !important;
}
.dropzone {
    text-align: center;
    padding: 20px;
    border: 1px dashed #00000099;
    background-color:#F4F4F4;
    color: #000000DE;
    cursor: pointer;
    /* margin-bottom: 20px; */
    flex-grow: 1;
    border-radius: 4px;
}
.dropzone .selected-file{
    padding: 10px; 
}
.manager .right-section-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px;
   
}
.manager .right-section-container-one .right-section-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.manager .right-section-header-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}
.manager .tableRow{
    display: flex;
}
.manager .tableCell{
    line-height: 2;
}

/***************************************** Manager ****************************************************/
.manager .Mui-focusVisible{
    background-color: "red"
}
.manager{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 6% 2% 2%;
}
.manager .leftContainer{
    width: 23%;
}
.manager .leftContainer .typography-title{
    padding: 20px;
}
.manager .rightContainer{
    flex-grow: 1;
    margin-left: 2%;
}
.manager .right-section-header.right{
    flex-grow: 1;
}

/***************************************** FileLibrary ****************************************************/


.manager .form-button-primary{
    height: 40px;
    padding: 10px;
    border-color: white;
    text-transform: uppercase !important;
    letter-spacing: 1.25px;
}

.manager .form-section {
    height: 85px;
    width: 400px;
}
.manager .form-section-double {
    height: 85px;
    width: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-between;
}
.manager .form-sub-section {
    height: 97px;
    width: 190px;
  }
.manager .dialogContent{
    padding-top: 20px;
}
.manager .accordionContent{
    padding-left: 30px;
}

.tableCell {
    border-right: 0px;
    padding: 2px 5px !important;
  }
  
.dropzone-container {
    padding: 0px 0px 20px 0px;
}  
/***************************************** DRG ****************************************************/

.manager .right-section-container-one{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px;
}
.manager .right-section-container-one .container-block{
    margin: 5px 0px;
}
.listUsers {
    padding: 0px !important;
}
.section{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.drgTable .tableCell{
    padding: 0px !important;
    border: 0px !important;
}

.DRG .dropzone{
    margin: 8px 0px !important;
}

.manage_subset{
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}