.viewer-container {
  padding: 20px;
}
.viewer-section-container {
  margin-top: 6%;
  width: 100%;
}
.viewer-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.viewer-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.viewer-header-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 650px;
  align-items: center;
}
.viewer-title {
  margin-bottom: 0px !important;
}
.viewer-button-news {
  height: 40px !important;
  width: 180px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.viewer-button-guidance {
  height: 40px !important;
  width: 205px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.viewer-button-code {
  height: 40px !important;
  width: 240px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.form-button-primary {
  height: 40px !important;
  width: 175px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
}
.viewer-section {
  border: 1px solid white;
}
.viewer-content-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px 20px;
  align-items: center;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}
.viewer-content {
  width: 95%;
  margin: 30px;
  border: 1px solid lightgrey;
}
.viewer-text {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.viewer-link {
  padding-left: 20px !important;
}
.viewermap-panel {
  padding-top: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.viewermap-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.viewermap-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}
.viewermap-header-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.viewer-modal-dialog {
  max-width: 100% !important;
}
.viewer-modal-dialog .MuiDialog-paper {
  max-width: 100% !important;
  min-width: 50% !important;
}
.modal-header-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.modal-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.modal-header-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
}
.modal-info-countText {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  padding-right: 15px;
}
.modal-infoText {
  font-size: 8px !important;
  line-height: 0.5 !important;
  text-align: left !important;
}
.MuiListItemText-primary {
  font-size: 12px !important;
  /* line-height: 0.5 !important; */
  text-align: left !important;
}
.search-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 10px;
}
.node-search {
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.search-result-text {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  padding-left: 20px !important;
}
.search-navigation {
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}
.MuiAccordionDetails-root {
  padding: 0px !important;
  border-top: 0px !important;
}
.viewer-search-table {
  border-bottom: 1px solid #e0e0e0;
}
.viewer-search-tableCell.head {
  border-top: 1px solid #e0e0e0;
  padding: 8px 5px !important;
}
.viewer-search-tableCell {
  border-right: 1px solid #e0e0e0;
  padding: 2px 5px !important;
}
.drg-panel {
  padding: 0px !important;
}
.drg-treeGrid {
  padding: 15px;
}
.drg-treeview-container {
  height: 300px;
  overflow-y: auto;
}
.drg-rules-count {
  font-size: 12px !important;
}
.drg-groupCode-tableContent {
  color: inherit !important;
  text-decoration-color: inherit !important;
  font-size: 12px !important;
  padding-left: 13px !important;
}
.drg-groupCode-linkContent {
  color: inherit !important;
  text-decoration-color: inherit !important;
  font-size: 12px !important;
}
.drg-diagram-container {
  padding-top: 15px;
}
.sksnodes-tree-container {
  width: 100%;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .sksnodes-tree-container .Mui-selected {
  background-color: #fff !important;
} */

.viewer-container-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.viewerPublic-section-container {
  margin-top: 1%;
  width: 100%;
}
.viewerProd-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
  background: #405B6B;
}
.viewerProd-button-news {
  height: 40px !important;
  width: 180px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #7E7E7E !important;
}
.viewerProd-button-guidance {
  height: 40px !important;
  width: 205px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #7E7E7E !important;
}
.viewerProd-button-code {
  height: 40px !important;
  width: 240px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #7E7E7E !important;
}
.viewerProd-accordion-summary {
  background: #405B6B !important;
}
.viewer-accordion-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.viewerPublic-text {
  color: white !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.viewerFrontPageContent {
  max-height: 300px;
  overflow-y: scroll;
  padding: 1%;
}

.viewerTest-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
  background: #00A1DF;
}
.viewerTest-button-news {
  height: 40px !important;
  width: 180px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #005487 !important;
}
.viewerTest-button-guidance {
  height: 40px !important;
  width: 205px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #005487 !important;
}
.viewerTest-button-code {
  height: 40px !important;
  width: 240px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.25px !important;
  background: #005487 !important;
}
.viewerTest-accordion-summary {
  background: #00A1DF !important;
}
.viewer-content-header .header-text {
  font-size: 14px !important;
  padding: 0px 15px 0px 0px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: bold !important;
}
.sks-layout{
  padding: 20px;
}